import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/breakpoints'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'
import GridContainer from 'components/atoms/grid-container'
import { ImageWithLazyAttributes } from 'components/atoms/images'
import Headline from '@typeform/ginger/dist/components/typography/headline/next'

export const Container = styled(GridContainer)`
  padding: 64px 0;
  justify-items: center;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.sm - 1}px) {
    overflow: hidden;
  }

  ${mediaQueries.sm`
    padding: 96px 0;
  `};
`

export const StyledHeadline = styled(Headline)`
  grid-column: 1 / -1;
  margin: 0 0 56px;

  ${mediaQueries.md`
    margin-bottom: 56px;
    grid-column: 2 / -2;
  `};

  ${mediaQueries.lg`
    margin-bottom: 64px;
  `};
`

export const Row = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  row-gap: 24px;

  ${mediaQueries.sm`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `};

  ${mediaQueries.md`
    padding: 0 64px;
    justify-content: space-between;
  `};

  ${mediaQueries.lg`
    grid-column: 2 / 12;
    padding: 0;
  `};
`

export const LogoItem = styled.div<{ logoWidth: number }>`
  width: ${({ logoWidth }) => `${logoWidth}px`};
  transform: scale(0.8);
  transition: transform 0.2s ease-in;
`

export const Image = styled(ImageWithLazyAttributes)`
  height: ${({ height }) => `${height}px`};
  width: auto;
  margin: 0 auto;
  display: block;

  @media (max-width: ${BREAKPOINTS.sm - 1}px) {
    margin: 0 auto;
  }
`

export const LogoContainer = styled.div`
  @media (max-width: ${BREAKPOINTS.md - 1}px) {
    flex: 0 0 33.3333%;
  }
`

export const Name = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.large};
  margin-top: 11px;
`

export const CarouselContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
`
