import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  TouchEvent,
  SyntheticEvent,
  ReactElement,
} from 'react'

import { TInternalCarouselSlideProps } from '../constants'

import {
  CarouselSled,
  CarouselSledContainer,
  CarouselItem,
} from './carousel-type-slide.styles'

const preventBubblingToParent = (event: SyntheticEvent) =>
  event.stopPropagation()
const triplicateForInfiniteCarousel = (children: ReactElement[]) => {
  return children.concat(children).concat(children)
}

export function CarouselTypeSlide(props: TInternalCarouselSlideProps) {
  const {
    children,
    currentIndex,
    gap = '0',
    goToIndex,
    isInfinite = false,
    totalPages,
  } = props

  const isRewindingRef = useRef(false)
  const sledContainerRef = useRef<HTMLDivElement>(null)
  const touchStartedAt = useRef(0)
  const touchMovedByPercentage = useRef(0)
  const slides = useMemo(() => {
    const validElements = children.filter(child => React.isValidElement(child))

    return !isInfinite
      ? validElements
      : triplicateForInfiniteCarousel(validElements)
  }, [children, isInfinite])
  const totalSlides = slides.length

  useEffect(() => {
    if (isInfinite) {
      goToIndex(totalPages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!sledContainerRef.current) {
      return
    }
    if (isRewindingRef.current) {
      sledContainerRef.current.style.transition = 'none'
      const value = -(currentIndex / totalSlides) * 100
      sledContainerRef.current.style.transform = `translateX(${value}%)`

      setTimeout(() => {
        if (sledContainerRef.current) {
          sledContainerRef.current.style.transition = ''
        }
      }, 10)

      isRewindingRef.current = false
    } else {
      const value = -(currentIndex / totalSlides) * 100
      sledContainerRef.current.style.transform = `translateX(${value}%)`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])

  const onTouchStart = useCallback((event: TouchEvent) => {
    touchStartedAt.current = event.touches[0].clientX
  }, [])

  const onTouchEnd = useCallback(() => {
    if (sledContainerRef.current) {
      sledContainerRef.current.style.transition = ''

      if (touchMovedByPercentage.current > 0) {
        if (currentIndex < totalSlides - 1) {
          goToIndex(currentIndex + 1)
        } else {
          const nextStaticPercentage = (currentIndex / totalSlides) * 100
          sledContainerRef.current.style.transform = `translateX(${-nextStaticPercentage}%)`
        }
      }

      if (touchMovedByPercentage.current < 0) {
        if (currentIndex !== 0) {
          goToIndex(currentIndex - 1)
        } else {
          const nextStaticPercentage = (currentIndex / totalSlides) * 100
          sledContainerRef.current.style.transform = `translateX(${-nextStaticPercentage}%)`
        }
      }
    }
  }, [currentIndex, goToIndex, totalSlides])

  const onCarouselTransitionEnd = useCallback(() => {
    if (isInfinite) {
      if (currentIndex > totalPages * 2) {
        isRewindingRef.current = true
        goToIndex(currentIndex - totalPages)
      }
      if (currentIndex < totalPages) {
        isRewindingRef.current = true
        goToIndex(currentIndex + totalPages)
      }
    }
  }, [currentIndex, goToIndex, isInfinite, totalPages])

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      if (sledContainerRef.current) {
        const currentX = event.touches[0].clientX
        const containerWidth = sledContainerRef.current.offsetWidth
        const movedByPixels = touchStartedAt.current - currentX
        const currentStaticPercentage = (currentIndex / totalSlides) * 100
        const movedByPercentage = (movedByPixels / containerWidth) * 100
        const absoluteCurrentPercentage =
          currentStaticPercentage + movedByPercentage

        touchMovedByPercentage.current = movedByPercentage
        sledContainerRef.current.style.transform = `translateX(${-absoluteCurrentPercentage}%)`
        sledContainerRef.current.style.transition = `none`
      }
    },
    [currentIndex, totalSlides]
  )

  return (
    <CarouselSled
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <CarouselSledContainer
        gap={gap}
        ref={sledContainerRef}
        totalPages={slides.length}
        onTransitionEnd={onCarouselTransitionEnd}
      >
        {slides.map((slide, index) => {
          const pageProps = {
            ...slide!.props,
            index,
            isCurrent: currentIndex % totalPages === index % totalPages,
          }
          return (
            <CarouselItem
              key={`slide-${index}`}
              onTransitionEnd={preventBubblingToParent}
            >
              {React.cloneElement(slide, pageProps)}
            </CarouselItem>
          )
        })}
      </CarouselSledContainer>
    </CarouselSled>
  )
}
