import React, { memo } from 'react'

import { LogoItem, Image, Name } from './logos-module.styles'

type TLogoProps = {
  height: number
  url?: string
  name: string
  showName: boolean
  width: number
}

const Logo = ({ height, url, name, showName, width }: TLogoProps) => {
  return (
    <LogoItem logoWidth={width}>
      <Image height={height} alt={name} src={url} />
      {showName && <Name>{name}</Name>}
    </LogoItem>
  )
}

Logo.displayName = 'Logo'

export default memo(Logo)
