import React, { useEffect } from 'react'
import { useMeasure } from 'react-use'

import { TCarouselItemProps } from '../constants'

import { Wrapper } from './item.styles'

export const Item = (props: TCarouselItemProps) => {
  const { child, index, currentIndex, updateWrapperHeight, ...rest } = props

  const [ref, { height: itemHeight }] = useMeasure<HTMLDivElement>()

  const isCurrentIndex = index === currentIndex

  useEffect(() => {
    isCurrentIndex && updateWrapperHeight(itemHeight)
  }, [isCurrentIndex, itemHeight, updateWrapperHeight])
  return (
    <Wrapper isActive={isCurrentIndex} ref={ref} {...rest}>
      {child}
    </Wrapper>
  )
}
