import React, { ReactElement, useMemo } from 'react'

import { CarouselTypeFade } from './carousel-type-fade'
import { CarouselTypeSlide } from './carousel-type-slide'
import { TCarouselProps, CarouselTypes } from './constants/carousel.types'

export const CarouselContext = React.createContext({})

export const Carousel = (props: TCarouselProps) => {
  const { children, hook, type = CarouselTypes.Slide, ...rest } = props
  const carouselProps = {
    ...hook,
    ...rest,
  }

  const items = React.Children.toArray(children)
    .map((child, index) => {
      if (React.isValidElement(child)) {
        const props = {
          ...hook,
          index,
          isCurrent: hook.currentIndex === index,
        }
        return React.cloneElement(child, props)
      }
      return null
    })
    .filter((a): a is ReactElement => a !== null)

  const CarouselType = useMemo(() => {
    return type === CarouselTypes.Fade ? CarouselTypeFade : CarouselTypeSlide
  }, [type])

  return (
    <CarouselContext.Provider value={hook}>
      <CarouselType {...carouselProps}>{items}</CarouselType>
    </CarouselContext.Provider>
  )
}

Carousel.displayName = 'Carousel'
