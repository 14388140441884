import styled from 'styled-components'

export const CarouselSled = styled.div`
  display: inline-flex;
`

export const CarouselSledContainer = styled.div<{
  totalPages: number
  gap: string
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({ totalPages }) => totalPages || 0},
    min-content
  );

  column-gap: ${props => props.gap || 0};
  position: relative;
  transition: transform 0.4s ease-in-out;
`

export const CarouselItem = styled.div`
  width: 100%;
  user-select: none;

  img {
    -webkit-user-drag: none;
  }
`
