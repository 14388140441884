import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import {
  IMAGE_LOADING,
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
} from '@typeform/ginger/dist/constants/image'
import { isVRT } from '@typeform/ginger/dist/util/vrt'
import React, { memo, useCallback } from 'react'
import { useInterval, useMedia } from 'react-use'
import { Carousel, useCarousel } from 'components/molecules/copenhagen-carousel'
import { getLayoutByType } from 'components/modules/logos-module/utils'
import { SIZE } from '@typeform/ginger/dist/components/typography/headline/next'

import {
  Container,
  Row,
  Image,
  LogoContainer,
  Name,
  CarouselContainer,
  StyledHeadline,
} from './logos-module.styles'
import { LAYOUT_TYPE } from './constants'
import Logo from './logo'
import { TLogosModuleType } from './types'

const defaultLayoutType = LAYOUT_TYPE.integrations
const USE_MEDIA_DEFAULT_STATE = false

type TLogo = {
  name: string
  url?: string
  image?: { url: string }
  loading?: keyof typeof IMAGE_LOADING
  decoding?: keyof typeof IMAGE_DECODING
  fetchpriority?: keyof typeof IMAGE_FETCHPRIORITY
}

type TLogosModuleProps = {
  title: string
  type: TLogosModuleType
  logos: TLogo[]
}

const LogosModule = ({
  title = '',
  type = defaultLayoutType,
  logos = [],
  ...rest
}: TLogosModuleProps) => {
  const carousel = useCarousel({
    isInfinite: true,
    totalPages: logos.length,
  })

  const isMobileWidth = useMedia(
    `(max-width: ${BREAKPOINTS.sm - 1}px)`,
    USE_MEDIA_DEFAULT_STATE
  )
  const isMobile = typeof window !== 'undefined' && isMobileWidth

  const showName = type === LAYOUT_TYPE.integrations

  const goToNextLogo = useCallback(() => {
    carousel.goToNextIndex()
  }, [carousel])

  useInterval(
    () => {
      goToNextLogo()
    },
    isVRT ? null : 1500
  )

  const { width, height } = getLayoutByType(type)

  return (
    <Container as='section' {...rest}>
      {title && (
        <StyledHeadline
          children={title}
          element={'h2'}
          extraSmallViewportsSize={SIZE.ZERO}
          smallViewportsSize={SIZE.ONE}
          mediumViewportsSize={SIZE.TWO}
          largeViewportsSize={SIZE.THREE}
        />
      )}
      {isMobile && (
        <CarouselContainer>
          {/* @ts-expect-error Carousel hans't been migrated to TS yet */}
          <Carousel gap='64px' hook={carousel} touch={false}>
            {logos.map(({ name, url, image }) => (
              <Logo
                height={height}
                key={`logo-${url}${image?.url}`}
                name={name}
                showName={showName}
                url={url || image?.url}
                width={width}
              />
            ))}
          </Carousel>
        </CarouselContainer>
      )}
      {!isMobile && (
        <Row>
          {logos.map(({ name, url, image }) => (
            <LogoContainer key={`logo-${url}${image?.url}`}>
              <Image height={height} alt={name} src={url || image?.url} />
              {showName && <Name>{name}</Name>}
            </LogoContainer>
          ))}
        </Row>
      )}
    </Container>
  )
}

LogosModule.displayName = 'LogosModule'

export default memo(LogosModule)
