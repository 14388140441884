import React, { ReactElement, ReactNode } from 'react'

import { useCarousel } from '../use-carousel'

// #region constants

export enum CarouselTypes {
  Slide = 'slide',
  Fade = 'fade',
}

// #endregion

// #region main component props

export type TCarouselPropsCommon = React.HTMLAttributes<HTMLDivElement> & {
  children: ReactElement[]
} & {
  type?: CarouselTypes
  hook: TUseCarouselResult
}

export type TCarouselFadeProps = TCarouselPropsCommon & {
  type: CarouselTypes.Fade
}

export type TCarouselSlideProps = TCarouselPropsCommon & {
  type?: CarouselTypes.Slide
  gap?: string
}

export type TCarouselProps = TCarouselFadeProps | TCarouselSlideProps

// #endregion

// #region hook types

export type TUseCarouselOptions = {
  totalPages: number
  isInfinite: boolean
}

export type TUseCarouselResult = ReturnType<typeof useCarousel>

// #endregion

// #region internal component props

export type TInternalCarouselProps = TUseCarouselResult &
  React.HTMLAttributes<HTMLDivElement> & { children: ReactElement[] }

export type TInternalCarouselSlideProps = TInternalCarouselProps & {
  gap?: string
}

export type TCarouselItemProps = {
  child: ReactNode
  index: number
  currentIndex: number
  updateWrapperHeight: (height: number) => void
}

// #endregion
