import React, { useState } from 'react'

import { TInternalCarouselProps } from '../constants'

import { CarouselWrapper } from './carousel-type-fade.styles'
import { Item } from './item'

export const CarouselTypeFade = (props: TInternalCarouselProps) => {
  const { children, currentIndex } = props
  const [wrapperHeight, setWrapperHeight] = useState(0)

  return (
    <CarouselWrapper height={wrapperHeight}>
      {React.Children.toArray(children).map((child, index) => (
        <Item
          child={child}
          currentIndex={currentIndex}
          index={index}
          key={`carousel-type-fade-item-${index}`}
          updateWrapperHeight={setWrapperHeight}
        />
      ))}
    </CarouselWrapper>
  )
}
