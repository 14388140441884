const integrations = 'integrations' as const
const partners = 'partners' as const
const awards = 'awards' as const
const compact = 'compact' as const

export const LAYOUT_TYPE = {
  integrations,
  partners,
  awards,
  compact,
}

export const LAYOUTS = {
  [LAYOUT_TYPE.integrations]: { height: 56, margin: 55, width: 105 },
  [LAYOUT_TYPE.partners]: { height: 35, margin: -2, width: 168 },
  [LAYOUT_TYPE.awards]: { height: 125, margin: 55, width: 125 },
  [LAYOUT_TYPE.compact]: { height: 25, width: 168 },
}
